import styles from './jumbotron.module.css'
import logo from '../../assets/landing/logo-black.svg'
import icon01 from '../../assets/landing/icon-01.svg'
import icon02 from '../../assets/landing/icon-02.svg'
import icon03 from '../../assets/landing/icon-03.svg'
import icon04 from '../../assets/landing/icon-04.svg'
import pfp from '../../assets/landing/pfp.png'
import star from '../../assets/landing/star.svg'
import vk from '../../assets/socials/vk.svg'
import tg from '../../assets/socials/tg.svg'
import discord from '../../assets/socials/discord.svg'
import youtube from '../../assets/socials/youtube.svg'
import { Link } from 'react-router-dom'
import { useAuthStore } from '../../hooks/useAuthStore'
import { config } from '../../config'

export default function Jumbotron() {
    const { isLoggedIn } = useAuthStore()

    const socials = [
        { id: 'tg', name: 'Telegram', image: tg, url: config.socials.tg },
        { id: 'vk', name: 'VK', image: vk, url: config.socials.vk },
        { id: 'discord', name: 'Discord', image: discord, url: config.socials.discord },
        { id: 'youtube', name: 'YouTube', image: youtube, url: config.socials.youtube },
    ]

    return (
        <div className={styles.jumbotron}>
            <header className={styles.jumbotron__header}>
                <img src={logo} width="40" height="40" alt="STARVELL" className={styles.logo} />

                <div className={styles.socials_circle}>
                    {socials.map(social => (
                        <a key={social.id} href={social.url} className={styles[`social_${social.id}`]} target="_blank" rel="noreferrer">
                            <img src={social.image} alt={social.name} />
                        </a>
                    ))}
                </div>
            </header>

            <div className={styles.jumbotron__cta}>
                <h1>Новый маркетплейс<br /> игровых товаров и услуг</h1>
                <h2>Найдите всё, что вам нужно по самым выгодным ценам!</h2>
                <Link to={isLoggedIn() ? '/giveaway' : '/auth'} className={styles.button_cta}>Присоединиться</Link>
            </div>

            <div className={styles.last_purchase}>
                <div className={styles.last_purchase__name}>
                    🔥 Fruit Notifier 🔥 | 🎁 Gift Gamepass 🎁
                </div>

                <div className={styles.user_card}>
                    <img src={pfp} width="34" height="35" alt="" />
                    <div className={styles.user_card__content}>
                        <div className={styles.user_card__username}>
                            <b>0xHearts.com</b>
                            <div className={styles.user_card__rating}>
                                5.0
                                <img src={star} width="15" height="14" alt="" />
                            </div>
                        </div>
                        <p>3 года на сайте, 4288 отзывов</p>
                    </div>
                </div>

                <div className={styles.user_card__price}>
                    <div className={styles.user_card__price__value}>500.29 ₽</div>
                    <div className={styles.user_card__price__icons}>
                        <img src={icon02} width="14" height="15" alt="" />
                        <img src={icon03} width="14" height="15" alt="" />
                        <img src={icon04} width="14" height="15" alt="" />
                        <img src={icon01} width="15" height="15" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}